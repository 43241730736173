import React from "react";
import bookCover from "src/images/generic_book_cover.jpg";
import { getCoverImageUrlFromPhysicalId, getCoverImageUrlFromPhysicalIdAndHeight } from "src/utils/asinUtils";
import debug from "src/utils/debugUtils";
import styles from "./BookCoverWithPlaceholder.module.scss";

type PropTypes = {
    asin: string,
    title: string,
    imagePxSize: number;
    imagePxType: "width"|"height";
    authors?: string,
    physicalId?: string,
    isVella?: boolean;
    lazy?: boolean;
    onLoad?: (wasSuccessful: boolean, image: HTMLImageElement | null | undefined) => void;
};

type State = {
    isLoaded: boolean;
    isTransitionEnabled: boolean;
    delayHasElapsed: boolean;
};

const TRANSITION_DELAY_MS = 300;

// Resizable Book cover with fallback placeholder and optional badging support.
// Wrap in a div that has the desired size constraints.
// Requires PhysicalID to be included in BookInfo
class BookCoverWithPlaceholder extends React.PureComponent<PropTypes, State> {
    loadingTransitionTimeoutId?: NodeJS.Timeout;
    coverImageRef?: HTMLImageElement | null;
    delayLoadingTimeoutId?: NodeJS.Timeout;

    constructor(props: PropTypes) {
        super(props);
        this.state = {
            isLoaded: false,
            isTransitionEnabled: false,
            delayHasElapsed: false,
        };
    }

    componentDidMount() {
        // Only show placeholder and animate loading transition if image isn't available immediately
        this.loadingTransitionTimeoutId = setTimeout(() => {
            if (!this.state.isLoaded) {
                this.setState({ isTransitionEnabled: true });
            }
        }, TRANSITION_DELAY_MS);
        if (debug.get("delayCoverLoads")) {
            const delayMs = 500 + (Math.random() * 10) * 100;
            debug.log(`Delaying (${delayMs}ms) load of ${this.props.asin}: ${this.props.title}`);
            this.loadingTransitionTimeoutId = setTimeout(() => {
                this.setState({ delayHasElapsed: true });
            }, delayMs);
        }
    }

    componentWillUnmount() {
        if (this.loadingTransitionTimeoutId) {
            clearTimeout(this.loadingTransitionTimeoutId);
            clearTimeout(this.delayLoadingTimeoutId);
        }
    }

    getClassNames = () => {
        const transition = this.state.isTransitionEnabled ? styles.transition : "";
        return `${styles.book} ${transition} ${styles.coverShadow} ${this.props.isVella ? styles.vella : ""}`;
    };

    getResponsiveImgSrcSet = (physicalId: string): string => {
        if (this.props.imagePxType === "width") {
            return `
                ${getCoverImageUrlFromPhysicalId(physicalId, this.props.imagePxSize)},
                ${getCoverImageUrlFromPhysicalId(physicalId, this.props.imagePxSize * 2)} 2x,
                ${getCoverImageUrlFromPhysicalId(physicalId, this.props.imagePxSize * 3)} 3x,
                ${getCoverImageUrlFromPhysicalId(physicalId, this.props.imagePxSize * 4)} 4x
            `;
        } else {
            return `
                ${getCoverImageUrlFromPhysicalIdAndHeight(physicalId, this.props.imagePxSize)},
                ${getCoverImageUrlFromPhysicalIdAndHeight(physicalId, this.props.imagePxSize * 2)} 2x,
                ${getCoverImageUrlFromPhysicalIdAndHeight(physicalId, this.props.imagePxSize * 3)} 3x,
                ${getCoverImageUrlFromPhysicalIdAndHeight(physicalId, this.props.imagePxSize * 4)} 4x
            `;
        }
    };

    handleLoad: React.ReactEventHandler<HTMLImageElement> = (
        loadEvent: React.SyntheticEvent<HTMLImageElement, Event>
    ) => {
        const element = loadEvent.target as HTMLImageElement;
        const didLoadSuccessfully = element.naturalHeight > 1;
        this.setState({ isLoaded: didLoadSuccessfully });
        this.props.onLoad?.(didLoadSuccessfully, this.coverImageRef);
    };

    render() {
        const delayCoverLoads = debug.get("delayCoverLoads");
        const waitForDelay = delayCoverLoads && !this.state.delayHasElapsed;
        const enableCoverLoads = !debug.get("disableCoverLoads") && !waitForDelay;
        const shouldShowPlaceholder = (!this.state.isLoaded && this.state.isTransitionEnabled) || !enableCoverLoads;

        return (
            <div className={this.getClassNames()} aria-label={`${this.props.title}`} role="img">
                <div className={styles.placeHolder} aria-hidden={true}>
                    <img className={`${styles.bookCover} ${shouldShowPlaceholder ? "" : styles.hidden}`} alt="" src={bookCover} />
                    <div className={`${styles.titleAndAuthor} ${shouldShowPlaceholder ? "" : styles.hidden}`}>
                        <div className={styles.spacer}></div>
                        <div className={styles.title}>{this.props.title}</div>
                        <div className={styles.author}>{this.props.authors}</div>
                    </div>
                </div>
                {enableCoverLoads && this.props.physicalId && (
                    <div aria-hidden={true}>
                        <img
                            className={`${styles.bookCover} ${!this.state.isLoaded ? styles.hidden : ""}`}
                            alt=""
                            srcSet={this.getResponsiveImgSrcSet(this.props.physicalId)}
                            onLoad={this.handleLoad}
                            onError={() => {
                                this.setState({ isLoaded: false });
                            }}
                            loading={this.props.lazy ? "lazy" : "eager"}
                            ref={(ref) => (this.coverImageRef = ref)}
                            crossOrigin="anonymous"
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default BookCoverWithPlaceholder;
