import { programCodeFromSticker } from "./programLogoUtils";


export const isRpl = (kindleProgramLowCost?: AapiKindleProgramLowCost): boolean => {
    return programCodeFromSticker(kindleProgramLowCost?.stickerStyleCode) === "READ_PLUS_LISTEN";
};

export const hasKuUpsell = (buyingOptions?: [AapiBuyingOption]): boolean => {
    return !!buyingOptions?.find(buyingOption => buyingOption.type === "KINDLE_UNLIMITED_UPSELL");
};

export const hasReadNowCta = (buyingOptions?: [AapiBuyingOption]): boolean => {
    return !!buyingOptions?.find(buyingOption => !!buyingOption.callToAction?.entity?.readNow?.url);
};

export const hasBorrowCta = (buyingOptions?: [AapiBuyingOption]): boolean => {
    return !!buyingOptions?.find(buyingOption => !!buyingOption.callToAction?.entity?.borrow?.url);
};

export const hasPurchaseCta = (buyingOptions?: [AapiBuyingOption]): boolean => {
    return !!buyingOptions?.find(buyingOption => buyingOption.callToAction?.entity?.oneClick?.url);
};

export const hasPreOrderCta = (buyingOptions?: [AapiBuyingOption]): boolean => {
    return !!buyingOptions?.find(buyingOption => !!buyingOption.callToAction?.entity?.oneClickPreorder?.url);
};

export const hasLimberCta = (buyingOptions?: [AapiBuyingOption]): boolean => {
    return !!buyingOptions?.find(buyingOption => buyingOption.type === "LIMBER");
};

export const hasSampleCta = (buyingOptions?: [AapiBuyingOption]): boolean => {
    return !!buyingOptions?.find(buyingOption => !!buyingOption.ebookSample?.entity?.endpoint?.url);
};

export const getByLine = (contributors?: [AapiContributor]): string | undefined => {
    return contributors?.filter(contributor => contributor.roles?.type === "author").map(author => author.name).join(", ")
        || contributors?.map(author => author.name).join(", ");
};

export const asLegacyOverview = (item?: AapiAsinMetadata) => {
    return item?.productOverview?.attributes?.map(attribute => {
        const it: OverviewMetadata = {
            labelId: attribute.label?.id,
            labelContent: attribute.label?.displayContent,
            value: attribute.granularizedValue?.displayContent,
        };
        return it;
    });
};

export const primaryPurchaseCta = (buyingOptions?: [AapiBuyingOption]): AapiBuyingOption | undefined => {
    const oneClickCta = buyingOptions?.find(buyingOption => !!buyingOption.callToAction?.entity?.oneClick?.url);
    const oneClickPreorderCta = buyingOptions?.find(buyingOption => !!buyingOption.callToAction?.entity?.oneClickPreorder?.url);
    return oneClickCta ?? oneClickPreorderCta;
};

export const asLegacySeries = (item?: AapiAsinMetadata): SeriesMetadata | undefined => {
    const series = item?.bookSeries?.singleBookView?.series;
    if (series) {
        return {
            message: series.message?.longMessage || series.message?.shortMessage,
            url: series.link?.url,
        };
    }
};

export const canBuyForFree = (buyingOptions?: [AapiBuyingOption]): boolean => {
    return buyingOptions?.find(buyingOption => buyingOption.callToAction?.entity?.oneClick?.url && buyingOption.type !== "LIMBER")?.price?.entity?.priceToPay?.moneyValueOrRange?.value?.amount === 0;
};

export const hasFreeAfrOption = (buyingOptions?: [AapiBuyingOption]): boolean => {
    return buyingOptions?.find(buyingOption => buyingOption.callToAction?.entity?.oneClick?.url && buyingOption.type === "LIMBER")?.price?.entity?.priceToPay?.moneyValueOrRange?.value?.amount === 0;
};
