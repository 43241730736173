import React, { useCallback, useEffect, useState } from "react";
import MesContext from "src/contexts/MesContext";
import { QuickViewIndexUpdateState } from "src/contexts/WorkflowContext";
import debug from "src/utils/debugUtils";

type PropTypes = {
    children?: React.ReactNode;
};

const selectedTagMap = new Map<string, number>();
const scrollToItemIndexMap = new Map<string, number>();

const MesContextManager: React.FC<PropTypes> = ({ children }) => {
    const [lastUpdateTimestamp, setLastUpdateTimestamp] = useState(0);

    useEffect(() => {
        return () => {
            selectedTagMap.clear();
            scrollToItemIndexMap.clear();
        };
    }, []);

    const getScrollToItemIndex = useCallback((key: string) => {
        return scrollToItemIndexMap.get(key) || -1;
    }, []);

    const setScrollToItemIndex = useCallback((key: string, index: number) => {
        scrollToItemIndexMap.set(key, index);
        setLastUpdateTimestamp(Date.now());
    }, []);

    const getSelectedTagIndex = useCallback((key: string) => {
        return selectedTagMap.get(key) || 0;
    }, []);

    const setSelectedTagIndex = useCallback((key: string, index: number) => {
        selectedTagMap.set(key, index);
        setLastUpdateTimestamp(Date.now());
    }, []);

    const getQvIndexUpdateCallback = useCallback((key: string) => {
        return (index: number, state: QuickViewIndexUpdateState) => {
            debug.log(`qvIndexUpdateCallback: ${key} :: ${index} - ${state}`);
            if (state === QuickViewIndexUpdateState.UPDATE) {
                setScrollToItemIndex(key, index);
            }
            if (state === QuickViewIndexUpdateState.CLOSED) {
                // TODO: acquire a11y focus on active cover?
                setScrollToItemIndex(key, index);
            }
        };
    }, [setScrollToItemIndex]);

    return (
        <MesContext.Provider
            value={{
                getScrollToItemIndex,
                getSelectedTagIndex,
                setScrollToItemIndex,
                setSelectedTagIndex,
                getQvIndexUpdateCallback,
                lastUpdateTimestamp,
            }}
        >
            {children}
        </MesContext.Provider>
    );
};

export default MesContextManager;
