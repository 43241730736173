import React, { memo, RefObject, useCallback, useContext, useEffect, useRef, useState } from "react";
import styles from "./QuickViewSlide.module.scss";
import { BookState, DEFAULT_BOOK_STATUS, NativeAPI } from "src/utils/deviceUtils";
import { CONTENT_TYPE, getDetailsUrl } from "src/utils/asinUtils";
import DeviceContext from "src/contexts/DeviceContext";
import { getCurrentBaseUrl } from "src/utils/urlUtils";
import debug from "src/utils/debugUtils";
import TranslationsContext from "src/contexts/TranslationsContext";
import { Theme } from "src/utils/themeUtils";
import dismissIcon from "src/images/close.svg";
import { newMetricsWithContext } from "src/utils/metricsUtils";
import QuickViewSlideMiniHeader from "../QuickViewSlideMiniHeader/QuickViewSlideMiniHeader";
import QuickViewSlideHeader from "../QuickViewSlideHeader/QuickViewSlideHeader";
import QuickViewSlideContent from "../QuickViewSlideContent/QuickViewSlideContent";
import AcquisitionManagerContext, { FailableResult, onFailure, onSuccess } from "src/contexts/AcquisitionManagerContext";
import focusHelper from "src/utils/focusHelper";
import { getVellaData, isSponsoredAsin } from "src/utils/asinMetadataUtils";
import { requestAnimationFrameEasedCallback } from "src/utils/animationUtils";
import FollowManagerContext from "src/contexts/FollowManagerContext";
import { fetchVellaStoryFollowInfo } from "src/utils/ajaxUtils";

type PropTypes = {
    mesKey: string;
    item: QuickViewAsinMetadata;
    getter: (asin: string) => Promise<QuickViewAsinMetadata>;
    dismiss: () => void;
    closeAll?: (event: React.UIEvent) => void;
    isInitialIndex: boolean;
    disableScrolling?: boolean;
    /** Container to use for IntersectionObserver visibility checks (performance optimization) */
    parentContainer?: RefObject<HTMLUListElement>;
    canLoad?: boolean;
    index: number;
    lastIndex: number;
};

type TouchData = {
    y: number;
    id: number;
    x: number;
};

const STATUS_POLLING_INTERVAL_MS = 100;

const QuickViewSlide: React.FC<PropTypes> = ({
    mesKey,
    item, // CAREFUL: Using this instead of metadata below is a source of bugs
    getter,
    dismiss,
    closeAll,
    isInitialIndex,
    disableScrolling,
    parentContainer,
    canLoad,
    index,
    lastIndex,
}: PropTypes) => {
    const context = useContext(DeviceContext);
    const translations = useContext(TranslationsContext);
    const [metadata, setMetadata] = useState(item);
    const itemRef = useRef<HTMLLIElement>(null);
    const scrollingContentAreaRef = useRef<HTMLDivElement>(null);
    const customerReviewsRef = useRef<HTMLDivElement>(null);
    const scrollMetricRecordedRef = useRef(false);
    const miniHeader = useRef<HTMLDivElement>(null);
    const miniHeaderTrigger = useRef<HTMLDivElement>(null);
    const [isVisible, setIsVisible] = useState<boolean>(isInitialIndex);
    const [isA11yVisible, setIsA11yVisible] = useState<boolean>(false);
    const isDarkTheme = context.theme === Theme.DARK;
    const backgroundColor = isDarkTheme ? styles.backgroundColorDark : styles.backgroundColorLight;
    const acquisitionManagerContext = useContext(AcquisitionManagerContext);
    const followManagerContext = useContext(FollowManagerContext);
    const [bookStatus, setBookStatus] = useState<BookStatus>(DEFAULT_BOOK_STATUS);
    const [bookActionInProgress, setBookActionInProgress] = useState<boolean>(false);
    const [vellaDataLoaded, setVellaDataLoaded] = useState<boolean>(false);
    const isReady = metadata.loaded === true && (!metadata.isShortStory || vellaDataLoaded);
    const [miniHeaderIsVisible, setMiniHeaderIsVisible] = useState(false);
    const [hasFetchedMetadata, setHasFetchedMetadata] = useState(false);
    const [hasFetchedVella, setHasFetchedVella] = useState(false);
    const [shouldReloadFollowData, setShouldReloadFollowData] = useState(false);
    const [reloadFollowDataInProgress, setReloadFollowDataInProgress] = useState(false);
    const [hasFullBook, setHasFullBook] = useState(false);
    const fetchFailuresCount = useRef(0);
    const fetchFailureError = useRef("");
    const a11yTarget = useRef<HTMLButtonElement>(null);
    const isSponsored = isSponsoredAsin(metadata);
    const scrollFixEnabled = context.device.isIOS;
    const scrollFixTouchDataRef = useRef<TouchData>({ x: -1, y: -1, id: -1 });
    const scrollFixTriggeredRef = useRef(false);
    const scrollFixLastScrollDeltaYRef = useRef(0);
    const scrollFixSlideOffsetThreshold = 10;
    const scrollFixVerticalToHorizontalRatioTriggerThreshold = 1.25;
    const asin = item.asin; // This is here to prevent the temptation to use item.asin and then forget about item != metadata
    const metrics = useCallback(() => {
        return newMetricsWithContext("QuickViewSlide", asin)
    }, [asin]);

    useEffect(() => {
        if (!isVisible) {
            setMiniHeaderIsVisible(false);
        }
    }, [isVisible]);

    const openDetailPage = useCallback(() => {
        const detailLink = metadata.additionalData?.detailLink;
        if (detailLink) {
            NativeAPI.openWebPage(detailLink, undefined, metadata.additionalData?.reftag);
        } else {
            const detailPageUrl = getDetailsUrl(
                getCurrentBaseUrl(),
                asin,
                metadata.isShortStory,
                metadata.additionalData?.reftag || "quick_view_ref_tag", // TODO add correct reftag?
                context.theme
            );
            NativeAPI.openWebPage(detailPageUrl);
        }
    }, [asin, context.theme, metadata.additionalData?.detailLink, metadata.additionalData?.reftag, metadata.isShortStory]);

    const fallbackToDetailPage = useCallback(() => {
        metrics().recordOperationalMetric(`DetailPageFallbackTriggered`, 1.0);
        openDetailPage();
    }, [metrics, openDetailPage]);

    const seeAllDetailsClick = useCallback(() => {
        metrics().recordBehavioralMetric("SeeAllDetails.click", 1);
        openDetailPage();
    }, [metrics, openDetailPage]);

    const redeemOfferByActionType = useCallback((actionType: string, callback: VoidFunction): Promise<FailableResult> => {
        debug.log(`BuyButton.redeemOfferByActionType ${asin} - ${actionType}`);
        setBookActionInProgress(true);
        return acquisitionManagerContext
            .redeemOfferByActionType(asin, actionType, callback)
            .then(onSuccess((result) => {
                debug.log(`Success ${asin} - ${actionType}: ${result.reasonCode}`);
                const m = metrics();
                m.recordOperationalMetric(`Redeem.${actionType}.success`, 1.0);
            }))
            .then(onFailure((result) => {
                debug.error(`Failure ${asin} - ${actionType}: ${result.reasonCode}`);
                const m = metrics();
                m.recordOperationalMetric(`Redeem.${actionType}.success`, 0.0);
                m.recordOperationalMetric(`Redeem.${actionType}.failure.${result.reasonCode}`, 1.0);
                if (result.reasonCode !== "ReturnAndBorrowTriggered") {
                    fallbackToDetailPage();
                }
            }))
            .catch(error => {
                debug.error(`Failed to redeem offer ${asin} - ${actionType} error: ${error}`);
                metrics().recordOperationalMetric(`Redeem.${actionType}.failure.ERROR`, 1.0, "" + error);
                fallbackToDetailPage();
                return error;
            })
            .finally(() => setBookActionInProgress(false));
    }, [acquisitionManagerContext, asin, metrics, fallbackToDetailPage]);

    const updateDownloadState = useCallback((contentType: CONTENT_TYPE, shouldRetryOnUnknown = false) => {
        return NativeAPI.getBookStatus(asin, contentType)
            ?.then((bookStatus) => {
                setBookStatus(bookStatus ?? DEFAULT_BOOK_STATUS);
                if (
                    bookStatus?.bookState === BookState.AZPluginBookStateInLibraryDownloading ||
                    bookStatus?.bookState === BookState.AZPluginBookStateInLibraryInCloud ||
                    (bookStatus?.bookState === BookState.AZPluginBookStateInLibraryUnknown && shouldRetryOnUnknown)
                ) {
                    setBookActionInProgress(true);
                    setTimeout(() => updateDownloadState(contentType, shouldRetryOnUnknown), STATUS_POLLING_INTERVAL_MS);
                } else {
                    setBookActionInProgress(false);
                }
            })
            .catch((error) => {
                metrics().recordOperationalMetric("getBookStatusError", 1.0);
                debug.error("Failed to retrieve book status: ", error);
                setBookStatus(DEFAULT_BOOK_STATUS);
            });
    }, [metrics, asin]);

    useEffect(() => {
        setHasFullBook(acquisitionManagerContext.isBorrowed(asin) || ((acquisitionManagerContext.isOwned(asin) || metadata.canReadNow === true) && !acquisitionManagerContext.wasReturned(asin)));
    }, [acquisitionManagerContext, asin, metadata.canReadNow]);

    const jumpToCustomerReviews = useCallback(() => {
        const elem = customerReviewsRef.current;
        if (elem) {
            elem.scrollIntoView({ behavior: "smooth", block: "start" });
            if (context.device.isIOS) {
                focusHelper.requestFocus(elem.parentElement, { preventScroll: true });
            } else {
                focusHelper.requestFocus(elem, { preventScroll: true });
            }
        }
    }, [customerReviewsRef, context]);

    useEffect(() => {
        if ((isInitialIndex || (canLoad && isVisible)) && !hasFetchedMetadata) {
            setHasFetchedMetadata(true);
            getter(asin).then((data) => {
                setMetadata((old) => {
                    if (data.loadFailed) {
                        fetchFailureError.current = data.error || "unknown";
                        if (fetchFailuresCount.current === 0) {
                            metrics().recordOperationalMetric("fetchRetryWasTriggered", 1, fetchFailureError.current);
                        }
                        fetchFailuresCount.current += 1;
                        setHasFetchedMetadata(data.unrecoverableError === true);
                    } else {
                        if (fetchFailuresCount.current > 0) {
                            metrics().recordOperationalMetric("fetchRetryWasSuccessful", 1, fetchFailureError.current);
                            metrics().recordOperationalMetric("fetchRetryWasSuccessfulAfterCount", fetchFailuresCount.current, fetchFailureError.current);
                        }
                        fetchFailuresCount.current = 0;
                    }
                    // Don't swap physicalId if one is already provided
                    const physicalId = old.physicalId || data.physicalId;
                    return { ...old, ...data, physicalId };
                });
            });
        }
    }, [isVisible, canLoad, isInitialIndex, hasFetchedMetadata, getter, asin, metrics]);

    useEffect(() => {
        if ((isInitialIndex || (canLoad && isVisible)) && metadata.loaded && metadata.isShortStory && !hasFetchedVella) {
            setHasFetchedVella(true);
            debug.log(`Fetching Vella data for ${asin}`);
            getVellaData(asin)
                .then(data => {
                    debug.log(`Finished fetching Vella data for ${asin}`);
                    if (fetchFailuresCount.current > 0) {
                        metrics().recordOperationalMetric("vellaFetchRetryWasSuccessful", 1, fetchFailureError.current);
                        metrics().recordOperationalMetric("vellaFetchRetryWasSuccessfulAfterCount", fetchFailuresCount.current, fetchFailureError.current);
                    }
                    setVellaDataLoaded(true);
                    setMetadata(old => { return { ...old, vellaData: data, vellaLoadFailed: false }; });
                    followManagerContext.updateFollowData(asin, data.followData);
                })
                .catch(error => {
                    fetchFailuresCount.current += 1;
                    fetchFailureError.current = error || "unknown";
                    const unrecoverableError = fetchFailureError.current.includes("404");
                    debug.log(`Error fetching Vella data for ${asin}: ${error}`);
                    if (fetchFailuresCount.current === 0 && !unrecoverableError) {
                        metrics().recordOperationalMetric("vellaFetchRetryWasTriggered", 1, fetchFailureError.current);
                    }
                    if (unrecoverableError) {
                        metrics().recordOperationalMetric("vellaFetchUnrecoverableError", 1, fetchFailureError.current);
                    }
                    setMetadata(old => { return { ...old, vellaLoadFailed: true, error, unrecoverableError: unrecoverableError }; });
                    setHasFetchedVella(unrecoverableError === true || fetchFailuresCount.current > 10);
                });

        }
    }, [isVisible, canLoad, isInitialIndex, asin, metadata.loaded, metadata.isShortStory, hasFetchedVella, followManagerContext, metrics]);

    useEffect(() => {
        if (isVisible && metadata.isShortStory && hasFetchedVella) {
            if (!context.isVisible && !shouldReloadFollowData) {
                debug.log(`Should reload follow data for ${asin}`);
                setShouldReloadFollowData(true);
            } else if (context.isVisible && shouldReloadFollowData && !reloadFollowDataInProgress) {
                setReloadFollowDataInProgress(true);
                debug.log(`Reloading follow data for ${asin}`);
                // Reload follow data
                fetchVellaStoryFollowInfo(asin)
                    .then(followData => {
                        debug.log(`Updating follow data for ${asin}`);
                        followManagerContext.updateFollowData(asin, followData);
                    })
                    .catch(error => {
                        debug.error(`Error refreshing follow status: ${error}`);
                    })
                    .finally(() => {
                        setShouldReloadFollowData(false);
                        setReloadFollowDataInProgress(false);
                    });
            }
        }
    }, [isVisible, hasFetchedVella, metadata.isShortStory, context.isVisible, shouldReloadFollowData, asin, followManagerContext, reloadFollowDataInProgress]);

    const getScrollData = useCallback(() => {
        const miniHeaderElement = miniHeader.current;
        const scrollingContentAreaElement = scrollingContentAreaRef.current;
        const miniHeaderTriggerElement = miniHeaderTrigger.current;

        if (miniHeaderElement && scrollingContentAreaElement && miniHeaderTriggerElement) {
            const miniHeaderRect = miniHeaderElement.getBoundingClientRect();
            const triggerRect = miniHeaderTriggerElement.getBoundingClientRect();
            const miniHeaderShow = (triggerRect.bottom - miniHeaderRect.bottom) < 0;
            return {
                scrollAreaScrollTop: scrollingContentAreaElement.scrollTop,
                miniHeaderOffset: miniHeaderShow ? miniHeaderRect.height - 20 : 0,
            };
        }
    }, []);

    // TODO: pull the 720, 24, and 12 from CSS style exports
    const slidePadding = (index == 0 || index == lastIndex) ? (window.innerWidth >= 720 ? 24 : 12) : 0;
    const slideWidth = itemRef.current?.clientWidth;
    const parentScrollOffset = () => {
        if (slideWidth) {
            const parentLeft = itemRef.current?.parentElement?.scrollLeft || 0;
            const slideLeftTarget = (slideWidth - slidePadding) * index;
            const offset = slideLeftTarget - parentLeft;
            return offset;
        }
        return 0;
    }

    const interruptParentScrollingAndSnapToCurrentSlide = () => {
        const parent = itemRef.current?.parentElement;
        if (parent) {
            const oldOverflowX = parent.style.overflowX;
            parent.style.overflowX = 'hidden';
            requestAnimationFrame(() => {
                const elem = itemRef.current?.parentElement;
                if (elem) { elem.style.overflowX = oldOverflowX }
            });
        }
    };

    const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
        if (scrollFixEnabled && event.touches.length === 1) {
            scrollFixLastScrollDeltaYRef.current = 0; // This will stop the JavaScript scroll animation on next step
            scrollFixTriggeredRef.current = false;
            const parentOffset = Math.abs(parentScrollOffset());
            if (parentOffset !== 0 && parentOffset < scrollFixSlideOffsetThreshold) {
                const touch = event.touches[0];
                scrollFixTouchDataRef.current = { y: touch.screenY, x: touch.screenX, id: touch.identifier };
            }
        }
        maybeStopPropagation(event);
    };

    const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
        if (scrollFixEnabled && !disableScrolling) {
            const touch = event.changedTouches[0];
            // This movement is for a different finger, or we haven't had a touch start during parent scroll - ignore it
            if (touch.identifier !== scrollFixTouchDataRef.current.id) {
                return;
            }
            const deltaY = scrollFixTouchDataRef.current.y - touch.screenY;
            scrollFixTouchDataRef.current.y = touch.screenY;
            if (!scrollFixTriggeredRef.current) {
                const deltaX = scrollFixTouchDataRef.current.x - touch.screenX;
                scrollFixTouchDataRef.current.x = touch.screenX;
                const deltaRatio = Math.abs(deltaY) / Math.abs(deltaX);
                if (deltaRatio < scrollFixVerticalToHorizontalRatioTriggerThreshold) {
                    return;
                }
                scrollFixTriggeredRef.current = true;
                interruptParentScrollingAndSnapToCurrentSlide();
            } else {
                scrollFixLastScrollDeltaYRef.current = deltaY;
            }
            requestAnimationFrame(() => scrollingContentAreaRef.current?.scrollBy({ top: deltaY, left: 0}))
        }
        maybeStopPropagation(event);
    };

    const handleTouchEnd = () => {
        if (!scrollFixEnabled) {
            return;
        }
        const deltaY = scrollFixLastScrollDeltaYRef.current;
        if (deltaY !== 0 && scrollFixTriggeredRef.current) {
            let cancelled = false;
            const isCancelledCallback = () => cancelled || scrollFixLastScrollDeltaYRef.current === 0;
            const updateCallback = (percentComplete: number) => {
                const multiplier = 1 - percentComplete;
                let scrollAmountY = deltaY * multiplier;
                if (scrollAmountY < 0.5 && scrollAmountY > -0.5) {
                    cancelled = true;
                    scrollFixLastScrollDeltaYRef.current = 0;
                } else {
                    if (deltaY < 0 && scrollAmountY > -1) { scrollAmountY = -1; }
                    if (deltaY > 0 && scrollAmountY < 1) { scrollAmountY = 1; }
                    scrollingContentAreaRef.current?.scrollBy({ top: scrollAmountY, left: 0 });
                }
            };
            requestAnimationFrameEasedCallback(2500, updateCallback, isCancelledCallback);
        }
        scrollFixTriggeredRef.current = false;
        scrollFixTouchDataRef.current = { y: -1, x: -1, id: -1 };
    };

    const maybeStopPropagation = (event: React.UIEvent) => {
        if (event.currentTarget.scrollTop > 0) {
            event.stopPropagation();
        }
    };

    const handleHeaderTap = () => {
        scrollingContentAreaRef.current?.scrollTo({ top: 0, behavior: "smooth" });
    };

    const handleScroll = (event: React.UIEvent) => {
        if (disableScrolling) {
            // Avoid calling expensive `.scrollTop()` layout function inside `maybeStopPropagation()` while dragging BottomSheet
            return;
        }

        maybeStopPropagation(event)
        // Only show/hide the mini-header once the metadata has been loaded
        if (!isReady) {
            return;
        }

        if (!scrollMetricRecordedRef.current) {
            scrollMetricRecordedRef.current = true;
            metrics().recordBehavioralMetric("Scrolled", 1);
        }

        // TODO: Replace this with an intersection observer?
        const miniHeaderElement = miniHeader.current;
        const miniHeaderTriggerElement = miniHeaderTrigger.current;
        if (!miniHeaderElement || !miniHeaderTriggerElement) {
            return;
        }
        const triggerRect = miniHeaderTriggerElement.getBoundingClientRect();
        const miniHeaderRect = miniHeaderElement.getBoundingClientRect();
        if (miniHeaderIsVisible) {
            if ((triggerRect.bottom - miniHeaderRect.bottom >= 0)) {
                setMiniHeaderIsVisible(false);
            }
        } else {
            if ((triggerRect.bottom - miniHeaderRect.bottom < 0)) {
                setMiniHeaderIsVisible(true);
            }
        }
    };

    useEffect(() => {
        const intersectionObserver = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsA11yVisible(true)
                } else {
                    setIsA11yVisible(false)
                }
            })
        }, {
            // Observe a small section in the middle of the screen to determine 
            // which slide is focused.
            rootMargin: "0% -49% 0% -49%",
            threshold: 0,
        });

        if (itemRef.current) {
            intersectionObserver.observe(itemRef.current);
        }
    }, [])

    useEffect(() => {
        // When the QuickViewCacheWarmer is preloading assets to cache them on the device,
        // we do not want to make an actual ASIN metadata query to avoid unnecessary network
        // traffic. This is not a real situation that would be faced by a customer.
        if (!asin) {
            return;
        }

        const intersectionObserver = new IntersectionObserver((entries) => {
            if (entries.filter((entry) => entry.isIntersecting).length > 0) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        }, {
            root: parentContainer?.current,
            // rootMargin: "0px -16px", // slide padding (4px) + content padding (12px) = 16px
            rootMargin: "0px 25%",
            threshold: 0,
        });

        if (itemRef.current) {
            intersectionObserver.observe(itemRef.current);
        }

        return () => {
            intersectionObserver?.disconnect();
        };
    }, [parentContainer, hasFetchedMetadata, asin])

    useEffect(() => {
        if (isInitialIndex) {
            itemRef.current?.scrollIntoView({ inline: "center" });
        }
    }, [isInitialIndex]);

    useEffect(() => {
        const now = Date.now();
        if (isInitialIndex && isReady) {
            metrics().recordTimeFromStart(`InitialItemReady${metadata.isShortStory ? ".vella" : ""}`, now);
        }
    }, [isInitialIndex, isReady, metadata.isShortStory, metrics]);

    const singleDismissPlease = (event: React.UIEvent) => {
        debug.log("singleDismissPlease");
        event.stopPropagation();
        dismiss();
    };

    return (
        <li
            className={`${styles.itemContainer} ${styles[context.theme]}`}
            ref={itemRef}
            aria-hidden={!isA11yVisible}
        >
                <div className={styles.topOverflowSection} onClick={singleDismissPlease}>
                    {closeAll
                        ? (<button className={styles.closeAllButton} onClick={closeAll}>{translations.getText("close-all")}</button>)
                        : (<div></div>)
                    }
                    <button
                        className={styles.dismissButton}
                        onClick={singleDismissPlease}
                        aria-label={
                            `${isSponsored ? translations.getText("sponsored-ad") : ""}${translations.formatText("qv-card-screen-reader-description", {title: metadata.title})}. ${translations.getText("dismiss")}`
                        }
                        ref={a11yTarget}
                    >
                        <img src={dismissIcon} alt="" />
                    </button>
                </div>
                <div className={styles.item}>
                {isVisible && <>
                    {isReady && acquisitionManagerContext.ownershipFetched &&
                        <QuickViewSlideMiniHeader
                            metadata={metadata}
                            backgroundColor={backgroundColor}
                            isVisible={miniHeaderIsVisible}
                            ref={miniHeader}
                            bookStatus={bookStatus}
                            sampleOwned={acquisitionManagerContext.isSampleOwned(asin)}
                            bookActionInProgress={bookActionInProgress}
                            hasFullBook={hasFullBook}
                            updateDownloadState={updateDownloadState}
                            redeemOfferByActionType={redeemOfferByActionType}
                            handleHeaderTap={handleHeaderTap}
                        />
                    }
                    <div
                        className={`${styles.scrollingContentContainer} ${disableScrolling ? styles.disableScrolling : ""}`}
                        onScroll={handleScroll}
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                        onTouchEnd={handleTouchEnd}
                        ref={scrollingContentAreaRef}
                    >
                        <div ref={miniHeaderTrigger}>
                            <QuickViewSlideHeader
                                metadata={metadata}
                                isReady={isReady && acquisitionManagerContext.ownershipFetched}
                                isSponsored={isSponsored}
                                onClickCustomerReviews={jumpToCustomerReviews}
                                bookStatus={bookStatus}
                                sampleOwned={acquisitionManagerContext.isSampleOwned(asin)}
                                bookActionInProgress={bookActionInProgress}
                                hasFullBook={hasFullBook}
                                updateDownloadState={updateDownloadState}
                                redeemOfferByActionType={redeemOfferByActionType}
                            />
                        </div>

                        <QuickViewSlideContent
                            mesKey={mesKey}
                            metadata={metadata}
                            isReady={isReady && acquisitionManagerContext.ownershipFetched}
                            ref={customerReviewsRef}
                            onClickCustomerReviews={jumpToCustomerReviews}
                            getScrollData={getScrollData}
                            disableScrolling={disableScrolling}
                            enableMes={context.enableMes}
                        />
                    </div>
                    { (isReady || metadata.loadFailed || metadata.vellaLoadFailed) && (
                        <div className={styles.detailsLinkContainer}>
                            <button className={styles.detailsLinkButton} type="button" onClick={seeAllDetailsClick}>
                                {translations.getText("see-all-details")}
                            </button>
                        </div>
                    )}
                </>}
                </div>
        </li>
    );
};

export default memo(QuickViewSlide);
