import React, { useCallback, useContext, useEffect, useState } from "react";
import AcquisitionManagerContext, { FailableResult } from "src/contexts/AcquisitionManagerContext";
import DeviceContext from "src/contexts/DeviceContext";
import WorkflowContext from "src/contexts/WorkflowContext";
import { grantFreeEpisodeOwnership, redeemAsinOffer, returnAsinBiFrost, throwOnInvalidAsin } from "src/utils/ajaxUtils";
import { getOffers, refreshVellaEpisodeData } from "src/utils/asinMetadataUtils";
import { CONTENT_TYPE } from "src/utils/asinUtils";
import debug from "src/utils/debugUtils";
import { BookState, NativeAPI } from "src/utils/deviceUtils";
import { recordBehavioralMetric, recordOperationalMetric } from "src/utils/metricsUtils";

type PropTypes = {
    children?: React.ReactNode;
    shouldSyncLibraryCallback?: VoidFunction;
    asins: string[];
};

const isValidOfferOfType = (offer: PersonalizedAction, actionType: string) => {
    const isUnconditional = offer.actionType === actionType && !offer.offer.conditional;
    if (isUnconditional && actionType === "Borrow") {
        // Cannot borrow Kindle Freetime Unlimited (KFTU) books via BiFrost
        return offer.actionProgram?.name !== "KFTU";
    }
    return isUnconditional;
};

const borrows = new Set<string>();
const returns = new Set<string>();
const ownedAsins = new Set<string>();
const sampleOwnedAsins = new Set<string>();
const openedAsins = new Set<string>();
const asinToBorrowedProgramCodeMap = new Map<string, string>();
const parentAsinRedirectMap = new Map<string, string>();
let lastOpenedAsin = "";

const addCount = (asin: string, metricName: string, value = 1) => {
    recordBehavioralMetric({namespace: "BorrowManager", qv_asin: asin}, metricName, value);
    recordOperationalMetric({namespace: "BorrowManager", qv_asin: asin}, metricName, value);
};

const isOwned = (bookStatus: BookStatus | null | undefined): boolean => {
    return !!(bookStatus && bookStatus?.bookState !== BookState.AZPluginBookStateInLibraryUnknown);
};

const fetchBookOwnershipSingle = (asin: string) => {
    let ebookOwned = false;
    return NativeAPI.getBookStatus(asin, CONTENT_TYPE.EBOK)
        ?.then(status => {
            if (isOwned(status)) {
                ownedAsins.add(asin);
                ebookOwned = true;
                return;
            }
        })
        .finally(() => {
            if (ebookOwned) { return; }
            NativeAPI.getBookStatus(asin, CONTENT_TYPE.EBSP)?.then(status => {
                if (isOwned(status)) {
                    sampleOwnedAsins.add(asin);
                }
            });
        });
};

const isBorrowed = (asin: string) => {
    return borrows.has(asin);
};

const wasReturned = (asin: string) => {
    return returns.has(asin);
};

const isOwnedAsin = (asin: string) => {
    return ownedAsins.has(asin);
};

const isSampleOwnedAsin = (asin: string) => {
    return sampleOwnedAsins.has(asin);
};

// The order of the apis conform with the openBook Function in deviceUtils
const hasNativeApiOpenBook = !!(window.QuickView?.openBookWithPayloadAsync || window.QuickView?.openBookAsync || window.WebViewWidget?.openBookAsync || window.webkit?.messageHandlers.openBook);

// Simple wrapper to allow opening Vella episodes on web when native API for book open isn't present
const openVellaEpisodeWrapper = (episode?: VellaEpisodeData) => {
    if (hasNativeApiOpenBook) {
        const contentType = episode?.hasOwnership ? CONTENT_TYPE.EBOK : CONTENT_TYPE.EBSP
        return NativeAPI.openBook(episode?.asin || "", "", "", contentType);
    }
    return NativeAPI.openWebPage(`/kindle-vella/episode/${episode?.asin}`);
};

const AcquisitionManager: React.FC<PropTypes> = ({ children, shouldSyncLibraryCallback, asins }) => {
    const [lastUpdateTimestamp, setLastUpdateTimestamp] = useState(Date.now());
    const workflowContext = useContext(WorkflowContext);
    const deviceContext = useContext(DeviceContext);
    const [ownershipFetched, setOwnershipFetched] = useState(false);

    useEffect(() => {
        return () => {
            borrows.clear();
            returns.clear();
            ownedAsins.clear();
            sampleOwnedAsins.clear();
            openedAsins.clear();
            asinToBorrowedProgramCodeMap.clear();
            lastOpenedAsin = "";
        };
    }, []);

    useEffect(() => {
        debug.log(`AcquisitionManager.isVisible: ${deviceContext.isVisible}`);
        if (deviceContext.isVisible === false) {
            openedAsins.clear();
        }
    }, [deviceContext.isVisible]);

    // Attempt to load the ownership status for all of the provided ASINs
    // TODO: pub sub this so we don't need to re-render all of the children when their status hasn't changed.
    useEffect(() => {
        const maybeBatchPromise = NativeAPI.getBatchBookOwnership(asins);
        if (maybeBatchPromise) {
            maybeBatchPromise.then(response => {
                if (response) {
                    asins.forEach(asin => {
                        const ownership = response[asin];
                        if (ownership?.owned) {
                            ownedAsins.add(asin);
                        }
                        if (ownership?.sampleOwned) {
                            sampleOwnedAsins.add(asin);
                        }
                    });
                }
            }).finally(() => setOwnershipFetched(true));
        } else {
            // Android and older iOS don't have the batch API
            Promise.all(asins.map(asin => fetchBookOwnershipSingle(asin))).finally(() => setOwnershipFetched(true));
        }
    }, [asins]);

    const returnAsin = useCallback(async (asin: string): Promise<string> => {
        throwOnInvalidAsin(asin);
        shouldSyncLibraryCallback?.();
        const response = await returnAsinBiFrost(asin);
        if (response || response === "") {
            addCount(asin, "Return.success");
            returns.add(asin);
            borrows.delete(asin);
            setLastUpdateTimestamp(Date.now());
            return response;
        } else {
            addCount(asin, "Return.failure");
            throw new Error("No response");
        }
    }, [shouldSyncLibraryCallback]);

    const borrowAsin = (asin: string, programCode: string) => {
        addCount(asin, "Borrow.success");
        borrows.add(asin);
        returns.delete(asin);
        setLastUpdateTimestamp(Date.now());
        asinToBorrowedProgramCodeMap.set(asin, programCode);
    };

    const buyAsin = (asin: string) => {
        addCount(asin, "Buy.success");
        ownedAsins.add(asin);
        setLastUpdateTimestamp(Date.now());
    };

    const isOpening = useCallback((asin?: string) => { return openedAsins.has(asin || ""); }, []);

    const openVellaEpisode = useCallback(async (episode?: VellaEpisodeData) => {
        debug.log("openVellaEpisode");
        debug.log(episode);
        if (!episode || !episode.asin) { return; }
        lastOpenedAsin = episode.asin;
        openedAsins.add(episode.asin);
        setLastUpdateTimestamp(Date.now());
        if (!episode.hasOwnership && episode.isFree) {
            if (await grantFreeEpisodeOwnership(episode.asin)) {
                episode.hasOwnership = true;
            }
        }
        openVellaEpisodeWrapper(episode);
        shouldSyncLibraryCallback?.();
        if (episode.storyAsin && episode.episodeNumber) {
            refreshVellaEpisodeData(episode.storyAsin, episode.episodeNumber)
                .then(() => setLastUpdateTimestamp(Date.now()));
        }
    }, [shouldSyncLibraryCallback]);

    const redeemInternal = async (asin: string, actionType: string, action: PersonalizedAction): Promise<FailableResult> => {
        const isBorrow = actionType === "Borrow"; // For metrics
        const output: FailableResult = { success: false, reasonCode: "", borrowLimit: false };
        const redeemResult = await redeemAsinOffer(asin, action);
        if (redeemResult.status === "Success") {
            addCount(asin, `Redeem.${actionType}.success`);
            if (isBorrow) { addCount(asin, `Borrow.${action.actionProgram.programCode}.success`); }
            output.success = true;
        } else if (redeemResult.status === "Violation") {
            const responseCode = redeemResult.resources?.[0]?.states?.[0]?.responseCode;
            output.reasonCode = responseCode;
            output.success = responseCode === "OWNERSHIP_VIOLATION"; // already owned
            output.borrowLimit = responseCode === "PROGRAM_BENEFIT_VIOLATION"; // borrow limit
            if (isBorrow) { addCount(asin, `Redeem.${actionType}.${action.actionProgram.programCode}.Violation.${responseCode}`); }
        } else if (redeemResult.status === "Error") {
            const responseCode = redeemResult.errorResult?.responseCode;
            output.reasonCode = responseCode;
            addCount(asin, `Redeem.${actionType}.Error.${responseCode}`);
        }
        return output;
    };

    const borrowLimitFlow = useCallback((asin: string, action: PersonalizedAction, focusCallback?: VoidFunction): FailableResult => {
        addCount(asin, `ReturnAndBorrowTriggered`);
        workflowContext.borrowLimit({
            asin: asin,
            action: action,
        }, focusCallback);
        return { success: false, reasonCode: "ReturnAndBorrowTriggered", borrowLimit: true };
    }, [workflowContext]);

    const redeemOfferByActionType = useCallback(async (asin: string, actionType: string, focusCallback?: VoidFunction): Promise<FailableResult> => {
        debug.log(`redeemOfferByActionType ${asin} - ${actionType}`);
        shouldSyncLibraryCallback?.();

        const output: FailableResult = { success: false, reasonCode: "", borrowLimit: false };
        // Try to redeem with the offers that are potentially already fetched.
        const offers = await getOffers(asin);
        // TODO: Should we sort the borrow offers by program or something?
        const actions = offers?.filter((offer) => isValidOfferOfType(offer, actionType)) || [];
        if (actions.length === 0) {
            output.reasonCode = "NO_ACTION_OF_TYPE";
            addCount(asin, `Redeem.${actionType}.failure.${output.reasonCode}`);
            return output;
        }

        const borrowLimitActions: PersonalizedAction[] = [];

        if (debug.get("forceReturnAndBorrowFlow") && actionType === "Borrow") {
            return borrowLimitFlow(asin, actions[0], focusCallback);
        }

        for (let i = 0; i < actions.length; i += 1) {
            const action = actions[i];
            const redeemResult = await redeemInternal(asin, actionType, action);
            if (redeemResult.success) {
                if (actionType === "Borrow") { borrowAsin(asin, action.actionProgram.programCode); }
                if (actionType === "Buy") { buyAsin(asin); }
                if (action.asin && action.asin !== asin) { parentAsinRedirectMap.set(asin, action.asin); }
                return redeemResult;
            }
            if (redeemResult.borrowLimit) { borrowLimitActions.push(action); }
            output.reasonCode = redeemResult.reasonCode;
        }

        // TODO: Should we try all? just one? some preferential sort/ordering?
        if (borrowLimitActions.length > 0) {
            return borrowLimitFlow(asin, borrowLimitActions[0], focusCallback);
        }
    
        return output;
    }, [shouldSyncLibraryCallback, borrowLimitFlow]);

    const openBook = useCallback(async (asin: string, title?: string, authors?: string, contentType?: CONTENT_TYPE, coverImagePosition?: QuickViewCoverImagePosition | undefined) => {
        shouldSyncLibraryCallback?.();
        const actualAsin = parentAsinRedirectMap.get(asin) || asin;
        debug.log(`ASIN: ${asin} - contentType: ${contentType} - actualAsin: ${actualAsin}`);
        if (lastOpenedAsin !== asin) {
            lastOpenedAsin = asin;
            openedAsins.add(asin);
            setLastUpdateTimestamp(Date.now);
        }
        if (hasNativeApiOpenBook) {
            // If the book is already borrowed before opening the quickivew, borrowedProgramCode won't be available
            // However this is a rare use case since when customer borrowed from other place, the book metadata shall 
            // already being synced to local
            // To improve, we need transaction type from remote as part of asin metadata
            // How TYP get Transaction Type( ~ originType) => https://tiny.amazon.com/ly3isyj2
            return NativeAPI.openBook(actualAsin, title, authors, contentType, coverImagePosition, asinToBorrowedProgramCodeMap.get(asin));
        }
        return NativeAPI.openWebPage(`https://read.${deviceContext.domain}/?asin=${actualAsin}`);
    }, [deviceContext.domain, shouldSyncLibraryCallback]);

    return (
        <AcquisitionManagerContext.Provider
            value={{
                isBorrowed: isBorrowed,
                wasReturned: wasReturned,
                isOwned: isOwnedAsin,
                isSampleOwned: isSampleOwnedAsin,
                returnAsin: returnAsin,
                redeemOfferByActionType: redeemOfferByActionType,
                openBook: openBook,
                openVellaEpisode: openVellaEpisode,
                isOpening: isOpening,
                ownershipFetched: ownershipFetched,
                lastUpdateTimestamp: lastUpdateTimestamp,
            }}
        >
            {children}
        </AcquisitionManagerContext.Provider>
    );
};

export default AcquisitionManager;
