import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import styles from "./AddToList.module.scss";
import { addToWishlist, createWishlistAapi, getWishlists, Wishlist, WishlistVisibility } from "src/utils/ajaxUtils";
import debug from "src/utils/debugUtils";
import DeviceContext from "src/contexts/DeviceContext";
import WorkflowContext from "src/contexts/WorkflowContext";
import TranslationsContext from "src/contexts/TranslationsContext";
import Spinner from "../Spinner/Spinner";
import { newMetricsWithContext } from "src/utils/metricsUtils";
import focusHelper from "src/utils/focusHelper";

type PropTypes = {
    asin: string;
    dismiss: () => void;
};

const AddToList: React.FC<PropTypes> = ({ asin, dismiss }: PropTypes) => {
    const deviceContext = useContext(DeviceContext);
    const workflowContext = useContext(WorkflowContext);
    const translations = useContext(TranslationsContext);
    const [wishlistsFetched, setWishlistsFetched] = useState(false);
    const [fetchInProgress, setFetchInProgress] = useState(false);
    const [showCreateList, setShowCreateList] = useState(false);
    const [wishlists, setWishlists] = useState<Wishlist[]>([]);
    const [createAsPrivate, setCreateAsPrivate] = useState(true);
    const [nameInputValue, setNameInputValue] = useState("");
    const defaultListName = translations.getText("default-create-wishlist-name");
    const [keyboardOffset, setKeyboardOffset] = useState(0);
    const metrics = newMetricsWithContext("AddToList", asin);
    let actionInProgress = false; // Used to prevent multiple taps triggering multiple AJAX calls
    const [selectedItem, setSelectedItem] = useState<number>(-1);
    const a11yInitialTarget = useRef<HTMLDivElement>(null);
    const aapiEligible = (!deviceContext.device.isIOS || deviceContext.isFullUrlSupported);

    const updateKeyboardOffset = () => {
        if (window.visualViewport) {
            const offset = window.innerHeight - window.visualViewport.height;
            setKeyboardOffset(offset);
        }
    };

    useEffect(() => {
        window.visualViewport?.addEventListener('resize', updateKeyboardOffset);
        updateKeyboardOffset();
        return () => window.visualViewport?.removeEventListener('resize', updateKeyboardOffset);
    }, []);

    const fetchWishlists = useCallback(() => {
        if (wishlistsFetched || fetchInProgress) {
            return;
        }
        metrics.recordOperationalMetric("CreateList.aapiEligible", aapiEligible ? 1.0 : 0.0);
        setFetchInProgress(true);
        // TODO: Revisit this to use AAPI AJAX once it's been proven out with the createWishlist flow
        getWishlists()
            .then((response) => {
                metrics.recordBehavioralMetric("Wishlists.count", response.wishlists.length);
                setWishlists(response.wishlists.sort((a, b) => a.name.localeCompare(b.name)));
                setWishlistsFetched(true);
            })
            .finally(() => setFetchInProgress(false));
    }, [aapiEligible, fetchInProgress, metrics, wishlistsFetched]);

    useEffect(() => {
        fetchWishlists();
    }, [fetchWishlists]);

    useEffect(() => {
        focusHelper.requestFocus(a11yInitialTarget.current);
    }, [showCreateList]);

    const defaultItemIndex = wishlists.findIndex((it) => it.isDefault);
    const defaultItem = defaultItemIndex !== -1 ? wishlists[defaultItemIndex] : undefined;

    const successText = translations.getText("add-to-list-success-text");
    const failureText = translations.getText("add-to-list-failure-text");

    const addToListSuccess = () => {
        metrics.recordOperationalMetric("AddToList.failure", 0);
        handleSuccess();
    }

    const createListSuccess = (success: boolean) => {
        metrics.recordOperationalMetric("CreateList.failure", success ? 0.0 : 1.0);
        if (success) {
            handleSuccess();
        } else {
            handleFailure();
        }
    }

    const handleSuccess = () => {
        workflowContext.infoMessage(successText);
        dismiss();
    };

    const handleFailure = () => {
        workflowContext.infoMessage(failureText);
    };

    const toggleItem = (index: number) => {
        const isSelected: boolean = selectedItem === index;
        if (isSelected) {
            setSelectedItem(-1);
        } else {
            setSelectedItem(index);
        }
    };

    const handleTap = () => {
        if (actionInProgress || selectedItem < 0 || selectedItem >= wishlists.length) {
            return;
        }
        const wishlist = wishlists[selectedItem];
        actionInProgress = true;
        metrics.recordBehavioralMetric("AddToList.click", 1);
        metrics.recordMetric("AddToList.isDefaultWishlist", selectedItem === defaultItemIndex ? 1 : 0);
        // TODO: Revisit this to use AAPI AJAX once it's been proven out with the createWishlist flow
        addToWishlist(asin, wishlist)
            .then(addToListSuccess)
            .catch((error) => {
                metrics.recordOperationalMetric("AddToList.failure", 1.0);
                debug.error(error);
            })
            .finally(() => (actionInProgress = false));
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => setNameInputValue(event.target.value);

    const createList = () => {
        metrics.recordBehavioralMetric("CreateList.click", 1);
        if (actionInProgress) {
            return;
        }
        actionInProgress = true;
        const newListName = nameInputValue.length > 0 ? nameInputValue : defaultListName;
        const visibility = createAsPrivate ? WishlistVisibility.PRIVATE : WishlistVisibility.PUBLIC;
        createWishlistAapi(asin, newListName, visibility)
            .then(result => createListSuccess(result.added)) // TODO: handle create success but add failure
            .catch(error => {
                metrics.recordOperationalMetric("CreateList.failure", 1.0);
                handleFailure();
                debug.error(error);
            })
            .finally(() => (actionInProgress = false));
    };

    const itemContainerClassName = `${styles.itemContainer} ${styles[deviceContext.theme]}`;

    const dismissCreateList = () => setShowCreateList(false);

    const maybeStopPropagation = (event: React.UIEvent) => {
        const ct = event.currentTarget;
        if (ct.scrollTop || ct.scrollHeight !== ct.clientHeight) {
            event.stopPropagation();
        }
    };

    // TODO: Sync with Drew re if we should default to "create wishlist" UX when the user has no current wishlists
    if (showCreateList) {
        return (
            <div
                className={`${itemContainerClassName} ${styles.createList}`}
                style={{
                    paddingBottom: `max(${keyboardOffset}px, env(safe-area-inset-bottom))`,
                }}
            >
                <div className={styles.chevronIconContainer}>
                    <div className={styles.chevronIcon} onClick={dismiss}/>
                </div>

                <header className={styles.header} ref={a11yInitialTarget} tabIndex={-1}>
                    {translations.getText("name-your-list")}
                </header>
                <div className={styles.createListTextInputContainer}>
                    <input
                        className={styles.textInput}
                        type="text"
                        placeholder={defaultListName}
                        value={nameInputValue}
                        onChange={handleInputChange}
                    />
                </div>

                <div className={[styles.createListRow, styles.privacySettings].join(" ")}>
                    <div className={styles.privacySettings}>
                        <input
                            className={styles.checkbox}
                            type="checkbox"
                            checked={createAsPrivate}
                            onChange={() => setCreateAsPrivate(!createAsPrivate)}
                        />
                        <label style={{ fontWeight: createAsPrivate ? "bold" : "normal" }}>
                            {translations.getText("private")}
                        </label>
                    </div>
                </div>

                <div className={styles.visibilityDescription}>
                    {createAsPrivate
                        ? translations.getText("only-you-can-see-this-list")
                        : translations.getText("anyone-can-search-for-and-see-this-list")
                    }
                </div>

                <footer className={styles.footerButtons}>
                    <button onClick={dismissCreateList}>
                        {translations.getText("cancel")}
                    </button>
                    <button className={styles.ctaButton} onClick={createList}>
                        {translations.getText("create-list")}
                    </button>
                </footer>
            </div>
        );
    }

    const getItemStyle = (index: number) => {
        return [
            styles.item,
            selectedItem === index ? styles.selectedItem : ""
        ].join(" ");
    };

    return (
        <div className={itemContainerClassName}>
            <div className={styles.chevronIconContainer}>
                <div className={styles.chevronIcon} onClick={dismiss}/>
            </div>
            <header className={styles.header} ref={a11yInitialTarget} tabIndex={-1}>
                {translations.getText("add-to-your-list")}
            </header>

            <main
                className={styles.main}
                onScroll={maybeStopPropagation}
                onTouchStart={maybeStopPropagation}
                onTouchMove={maybeStopPropagation}
                onTouchEnd={maybeStopPropagation}
                onTouchCancel={maybeStopPropagation}
                role="listbox"
                aria-multiselectable="false"
            >
                {!wishlistsFetched && (<div className={styles.spinnerBox}>
                    <Spinner />
                </div>) }

                {defaultItem && (
                    <button
                        className={getItemStyle(defaultItemIndex)}
                        key={defaultItem.id}
                        onClick={() => toggleItem(defaultItemIndex)}
                        role="option"
                        aria-selected={selectedItem === defaultItemIndex}
                    >
                        <span className={styles.itemTitle}>{defaultItem.name}</span>
                        <span className={styles.itemMetadata}>
                            {defaultItem.isPrivate
                                ? translations.getText("private")
                                : translations.getText("public")}
                        </span>
                        <span className={styles.itemMetadataSeparator} aria-hidden="true">|</span>
                        <span className={styles.itemMetadata}>{translations.getText("default")}</span>
                    </button>
                )}

                {wishlistsFetched &&
                    wishlists.map((item, index) => {
                        if (index === defaultItemIndex) {
                            return;
                        }

                        return (
                            <button
                                className={getItemStyle(index)}
                                key={item.id}
                                onClick={() => toggleItem(index)}
                                role="option"
                                aria-selected={selectedItem === index}
                            >
                                <span className={styles.itemTitle}>{item.name}</span>
                                <span className={styles.itemMetadata}>
                                    {item.isPrivate
                                        ? translations.getText("private")
                                        : translations.getText("public")}
                                </span>
                            </button>
                        );
                    })}

            </main>


            <footer className={styles.footer}>
                { aapiEligible && (
                    <div className={styles.createAList}>
                        <button
                            className={[styles.item, styles.createAList].join(" ")}
                            onClick={() => setShowCreateList(true)}
                            aria-label={translations.getText("create-a-list")?.replace("+", "")}
                        >
                            {translations.getText("create-a-list")}
                        </button>
                    </div>
                )}

                <div className={styles.footerButtons}>
                    <button className={styles.cancelButton} onClick={dismiss}>
                        {translations.getText("cancel")}
                    </button>
                    {selectedItem < 0 && (
                        <button className={[styles.ctaButton, styles.disabledButton].join(" ")}>
                            {translations.getText("add-to-list")}
                        </button>
                    )}
                    {selectedItem >= 0 && (
                        <button className={styles.ctaButton} onClick={handleTap}>
                            {translations.getText("add-to-list")}
                        </button>
                    )}
                </div>

            </footer>
        </div>
    );
};

export default AddToList;
