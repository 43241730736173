import React, { memo, useContext, useState } from "react";
import BookCoverWithPlaceholder from "../BookCoverWithPlaceholder/BookCoverWithPlaceholder";
import { getProgramSticker } from "src/utils/programLogoUtils";
import styles from "./QuickViewSlideHeader.module.scss";
import WorkflowContext from "src/contexts/WorkflowContext";
import debug from "src/utils/debugUtils";
import DeviceContext from "src/contexts/DeviceContext";
import TranslationsContext from "src/contexts/TranslationsContext";
import { StarRatingBar } from "../StarRatingBar/StarRatingBar";
import AddToListButton from "../AddToListButton/AddToListButton";
import ShareButton from "../ShareButton/ShareButton";
import BookBuyButton from "../BookBuyButton/BookBuyButton";
import AudioSampleButton from "../AudioSampleButton/AudioSampleButton";
import { CONTENT_TYPE } from "src/utils/asinUtils";
import { FailableResult } from "src/contexts/AcquisitionManagerContext";
import SponsoredLabel from "src/components/SponsoredLabel/SponsoredLabel";
import { NativeAPI } from "src/utils/deviceUtils";
import FollowStoryButton from "../Vella/FollowStoryButton";
import vellaTopFavedIcon from "src/images/vella_fave_icon_flat.png";
import { interpolateTranslation, releaseDateString } from "../Translations/Translations";
import weblabUtils from "src/utils/weblabUtils";
import RplUpsell from "../RplUpsell/RplUpsell";

type PropTypes = {
    metadata: QuickViewAsinMetadata;
    isReady: boolean;
    isSponsored: boolean;
    onClickCustomerReviews: VoidFunction;
    updateDownloadState: (contentType: CONTENT_TYPE) => void;
    redeemOfferByActionType: (actionType: string, callback: VoidFunction) => Promise<FailableResult>;
    hasFullBook: boolean;
    bookStatus: BookStatus;
    sampleOwned: boolean;
    bookActionInProgress: boolean;
};

const addToLibraryFreeSupportedAmazonUrls = [
    "Amazon.co.jp",
];

const VellaLikesIcon = () => (
    <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img">
        <path fillRule="evenodd" clipRule="evenodd" d="M2.82422 6.65915C2.36012 6.65915 1.89602 6.70811 1.77872 6.77339C1.44824 6.96209 1.24424 7.22933 1.32278 7.92293C1.38092 8.44925 2.6243 14.3969 2.8997 14.9416C3.0425 15.1354 3.11696 15.2384 4.30832 15.2394L13.1334 15.2414V9.00719L11.0668 9.00821L10.9291 8.78279C10.5721 8.20751 8.78817 5.30561 8.58314 4.60589C8.29142 3.61649 8.25164 1.79273 8.27816 1.06343C7.68554 0.920631 7.26326 1.02365 6.69308 1.89269C6.4187 2.31191 6.27284 2.82497 6.25142 3.46145C6.23102 3.97145 6.24938 4.38251 6.30548 4.75583C6.35138 5.05775 6.39218 5.35967 6.434 5.67077L6.5717 6.67037L3.01496 6.66221C2.95172 6.66017 2.8895 6.65915 2.82422 6.65915ZM14.082 16.1921L4.3073 16.189C2.94458 16.188 2.53454 16.0534 2.11124 15.4689C1.69814 14.8957 0.385404 8.09021 0.379284 8.03003C0.267084 7.04981 0.572064 6.36743 1.30952 5.94923C1.75934 5.69423 2.74262 5.70137 3.03026 5.71157L5.48336 5.71871C5.44664 5.44127 5.4089 5.16995 5.36708 4.89965C5.30078 4.46615 5.28038 3.99797 5.3018 3.42575C5.33036 2.61281 5.52722 1.94063 5.89952 1.37147C6.40952 0.593211 7.11332 -0.165669 8.45258 0.107691C8.8412 0.186231 9.24614 0.573831 9.2288 1.06241C9.19208 1.91207 9.26654 3.56753 9.49298 4.33763C9.61742 4.75889 10.7955 6.75197 11.5972 8.05859L14.082 8.05553V16.1921Z" fill="currentColor"/>
    </svg>
);

const VellaEpisodesIcon = () => (
    <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img">
        <path fillRule="evenodd" clipRule="evenodd" d="M2.85498 7.57593H8.81298V6.57593H2.85498V7.57593Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M2.85498 4.7981H8.81298V3.7981H2.85498V4.7981Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M2.85498 10.353H8.81298V9.35303H2.85498V10.353Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M0.259033 14.152H11.368V0H0.259033V14.152ZM1.25903 13.152H10.368V0.999H1.25903V13.152Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M13.3548 16.1116H2.74683V15.1116H12.3548V2.9856H13.3548V16.1116Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M14.3565 4.97534V17.1013H4.74854V18.1013H15.3565V4.97534H14.3565Z" fill="currentColor"/>
    </svg>
);

const VellaCompletedIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img">
        <path fillRule="evenodd" clipRule="evenodd" d="M14.5 8C14.5 11.5899 11.5899 14.5 8 14.5C4.41015 14.5 1.5 11.5899 1.5 8C1.5 4.41015 4.41015 1.5 8 1.5C11.5899 1.5 14.5 4.41015 14.5 8ZM8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.85786 12.1421 0.5 8 0.5C3.85786 0.5 0.5 3.85786 0.5 8C0.5 12.1421 3.85786 15.5 8 15.5ZM7.05386 11.0869L12.3629 5.48694L11.6371 4.79894L6.68853 10.0188L4.3605 7.59648L3.6395 8.28941L6.3305 11.0894L6.69347 11.4671L7.05386 11.0869Z" fill="currentColor"/>
    </svg>
);

const openKindleStoreTermsOfUse = () => NativeAPI.openWebPage('/gp/help/customer/display.html?nodeId=201014950');

const openSellerOfRecordLegalText = () => NativeAPI.openWebPage('/gp/help/customer/display.html?ie=UTF8&nodeId=202008070');

const QuickViewSlideHeader: React.FC<PropTypes> = ({ 
    metadata,
    isReady,
    isSponsored,
    onClickCustomerReviews,
    updateDownloadState,
    redeemOfferByActionType,
    hasFullBook,
    bookStatus,
    sampleOwned,
    bookActionInProgress
}: PropTypes) => {
    const context = useContext(DeviceContext);
    const workflowContext = useContext(WorkflowContext);
    const translations = useContext(TranslationsContext);
    const amazonUrl = `A${context.domain.slice(1)}`;
    const isVella = !!metadata.isShortStory;
    const [truncateTitle, setTruncateTitle] = useState(true);
    const toggleTruncateTitle = () => setTruncateTitle(!truncateTitle);
    const [truncateAuthors, setTruncateAuthors] = useState(true);
    const toggleTruncateAuthors = () => setTruncateAuthors(!truncateAuthors);
    const canAddToLibraryForFree = !!(metadata.canBuyForFree && (addToLibraryFreeSupportedAmazonUrls.includes(amazonUrl) || debug.get("forceAddToYourLibraryForFree")));

    const tapsForDebug = 5;
    let taps = 0;
    let timeout: NodeJS.Timeout | undefined = undefined;

    const handleCoverTap = () => {
        clearTimeout(timeout);
        taps += 1;
        if (taps >= tapsForDebug) {
            taps = 0;
            if (debug.get("enableDebugGooey")) {
                workflowContext.showDebugGooey();
            } else {
                weblabUtils.isT1AndTrigger("KINDLE_QV_DEBUG_GOOEY_ACCESS_913834").then(isT1 => {
                    if (isT1) {
                        workflowContext.showDebugGooey();
                    }
                });
            }
        } else {
            debug.log(`You are ${tapsForDebug - taps} taps away from the debug GUI`);
            timeout = setTimeout(() => { taps = 0; }, 2000);
        }
    };

    const stickerCode = getProgramSticker(context.theme, context.domain, metadata.kindleProgram, metadata.isAFR);

    const getReleaseDateSection = (): JSX.Element | undefined => {
        if (metadata.releaseDate && metadata.releaseDate.isPrerelease && (metadata.canPreOrder || !metadata.canBuy)) {
            return (
                <div className={styles.releaseDateHolder}>
                    {interpolateTranslation(
                        translations.getText("not-yet-released-notice"),
                        "releaseDate",
                        (<span className={styles.releaseDate}>{releaseDateString(context.locale, metadata.releaseDate?.date)}</span>)
                    )}
                </div>
            );
        }
    };

    const getStickerSection = (): JSX.Element | undefined => {
        if (stickerCode) {
            return (<div className={metadata.isRPL ? styles.programBadgeRpl : styles.programBadge}>
                <img src={stickerCode.src} alt={translations.getText(stickerCode.altKey)} />
            </div>);
        }
    };

    const getHeaderBottomSection = (): JSX.Element => {
        const isJPAmazon = context.domain === "amazon.co.jp";

        return (<>
            {metadata.isRPL && <RplUpsell item={metadata}/>}

            {!metadata.canPreOrder && (<div className={styles.bottomSection}>
                <BookBuyButton 
                    item={metadata}
                    bookStatus={bookStatus} 
                    sampleOwned={sampleOwned} 
                    bookActionInProgress={bookActionInProgress} 
                    hasFullBook={hasFullBook} 
                    canAddToLibraryForFree={canAddToLibraryForFree}
                    updateDownloadState={updateDownloadState} 
                    redeemOfferByActionType={redeemOfferByActionType}
                />
                {metadata.audibleUrl && !metadata.canPreOrder && <AudioSampleButton item={metadata} />}
            </div>)}

            {!hasFullBook && !metadata.canBorrow && canAddToLibraryForFree && (
                <div className={styles.addForFreeLegalText}>
                    <div>
                    {metadata.sellerOfRecord && !isJPAmazon && (<>
                        { translations.formatText("sold-by-seller-of-record", { sellerOfRecord: metadata.sellerOfRecord }) }
                        <br />
                    </>)}
                    {interpolateTranslation(
                        translations.getText("add-to-your-library-for-free-legal-warning"),
                        "kindleStoreTermsOfUse",
                        <span className={styles.textButton} tabIndex={0} role="button" onClick={openKindleStoreTermsOfUse}>
                            {translations.getText("kindle-store-terms-of-use")}
                        </span>
                    )}</div>
                    {metadata.sellerOfRecord && isJPAmazon && (
                        <div className={styles.textButton} tabIndex={0} role="button" onClick={openSellerOfRecordLegalText}>
                            {translations.formatText("sold-by-seller-of-record", { sellerOfRecord: metadata.sellerOfRecord })}
                        </div>
                    )}
                </div>
            )}
            <hr className={styles.sectionDivider} aria-hidden />
        </>)
    };

    const vellaEpisodesCount = metadata.vellaData?.collectionSize || 0;
    const vellaLikeCount = metadata.vellaData?.likeCount?.toLocaleString(context.locale);

    return (
        <div className={`${styles.quickViewSlideHeader} ${styles[context.theme]}`}>
            <div className={styles.bookDetailHeader}>
                <div
                    className={`${styles.coverImageContainer} ${metadata.isShortStory ? styles.vella : ""}`}
                    onClick={handleCoverTap}
                >
                    <BookCoverWithPlaceholder
                        asin={metadata.asin}
                        authors={metadata.authors}
                        title={metadata.title ?? ""}
                        physicalId={metadata.physicalId}
                        imagePxSize={200}
                        imagePxType="width"
                        isVella={metadata.isShortStory}
                    />
                </div>
                <div className={styles.metadataContainer}>
                    { isSponsored && <SponsoredLabel/>}
                    <div
                        className={[styles.title, truncateTitle ? styles.truncate : ""].join(" ")}
                        onClick={toggleTruncateTitle}
                        role="heading" aria-level={1}
                    >{metadata.title}</div>
                    <div className={styles.authorsContainer}>
                        <div className={[styles.authors, truncateAuthors ? styles.truncate : ""].join(" ")} onClick={toggleTruncateAuthors}>{metadata.authors}</div>
                    </div>
                    { isReady && (<>
                        { metadata.reviewsSummary !== null && !isVella && ( // Hide for unreleased ASINs (undefined while loading, null if missing)
                            <StarRatingBar
                                reviewsSummary={metadata.reviewsSummary}
                                onTapCallback={onClickCustomerReviews}
                            />
                        )}

                        {isVella && (
                            <div className={styles.vellaIcons} role="group" aria-label={translations.getText("falkor-story-aria-stats")}>
                                <div className={styles.vellaEpisodesIcon} role="group">
                                    <VellaEpisodesIcon />
                                    <div aria-label={
                                        vellaEpisodesCount > 1
                                            ? translations.formatText("falkor-general-episodes",  { vellaEpisodesCount })
                                            : translations.formatText("falkor-general-episode",  { vellaEpisodesCount })
                                    }>
                                        { vellaEpisodesCount.toLocaleString(context.locale) }
                                    </div>
                                </div>
                                {metadata.vellaData?.completionStatus === "COMPLETE" && (<div className={styles.vellaCompletedIcon} role="group">
                                    <VellaCompletedIcon />
                                    <div aria-label={translations.getText("falkor-story-status-completed-aria")}>
                                        { translations.getText("vella-completed-text") }
                                    </div>
                                </div>)}
                                <div className={styles.vellaLikesIcon} role="group">
                                    <VellaLikesIcon />
                                    <div aria-label={translations.formatText("falkor-storefront-like-1", { likeCount: vellaLikeCount })}>
                                        { vellaLikeCount }
                                    </div>
                                </div>
                                {metadata.vellaData?.faveRank && (<div className={styles.vellaTopFavedIcon} role="group">
                                    <img src={vellaTopFavedIcon} alt="" aria-hidden="true" />
                                    { translations.getText("vella-top-faved-text") }
                                </div>)}
                            </div>
                        )}

                        { getStickerSection() }

                        <div className={styles.buttonContainer}>
                            {isVella
                                ? (<FollowStoryButton asin={metadata.asin} />)
                                : (<AddToListButton asin={metadata.asin} />)}
                            <ShareButton item={metadata} />
                        </div>
                    </>)}
                </div>
            </div>
            {getReleaseDateSection()}
            { isReady && getHeaderBottomSection()}
        </div>
    );
};

export default memo(QuickViewSlideHeader);
