import React from "react";
import { QuickViewIndexUpdateCallback } from "./WorkflowContext";

export interface MesContextI {
    getScrollToItemIndex: (key: string) => number;
    getSelectedTagIndex: (key: string) => number;
    setScrollToItemIndex: (key: string, index: number) => void;
    setSelectedTagIndex: (key: string, index: number) => void;
    getQvIndexUpdateCallback: (key: string) => QuickViewIndexUpdateCallback;
    lastUpdateTimestamp: number;
}

const noOp = () => { /**/ };
const noOpZero = () => 0;
const MesContext = React.createContext<MesContextI>({
    getScrollToItemIndex: noOpZero,
    getSelectedTagIndex: noOpZero,
    setScrollToItemIndex: noOp,
    setSelectedTagIndex: noOp,
    getQvIndexUpdateCallback: () => noOp,
    lastUpdateTimestamp: 0,
});

export default MesContext;
